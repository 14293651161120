import { Rectangle } from "./rectangle";
import { Vector2D } from "./vector2d";
export class Pitch {
    id;
    name;
    position;
    constructor(position, name, id) {
        this.id = id;
        this.name = name;
        this.position = position;
    }
    copy(new_position) {
        return new Pitch(new_position, this.name, this.id);
    }
    static load(serialized) {
        return new Pitch(new Rectangle(new Vector2D(serialized.x1, serialized.y1), new Vector2D(serialized.x2, serialized.y2)), serialized.name, serialized.id);
    }
    save() {
        return {
            id: this.id,
            name: this.name,
            x1: this.position.origin.x,
            y1: this.position.origin.y,
            x2: this.position.corner.x,
            y2: this.position.corner.y,
        };
    }
}
