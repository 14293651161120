import { AxiosError } from "axios";
import { toast } from "bulma-toast";
export class APIError extends Error {
    reason;
    axios_error = null;
    constructor(reason, endpoint) {
        super(endpoint); // sets this.message to the endpoint
        this.reason = reason;
        Object.setPrototypeOf(this, APIError.prototype);
        if (reason instanceof AxiosError) {
            this.axios_error = reason;
        }
    }
    format() {
        let msg = "Unbekannter Fehler, bitte wiederholen! Besteht das Problem länger, bitte Admin benachrichtigen!";
        let code = "U";
        const result = () => `${msg} (Fehlercode: ${code}/${this.message})`;
        if (this.axios_error === null)
            return result();
        switch (this.axios_error.code) {
            case "ECONNABORTED":
                // API unerreichbar
                msg =
                    "API antwortet nicht, bitte später wiederholen! Besteht das Problem länger, bitte Admin benachrichtigen!";
                code = "D";
                break;
            case "ERR_NETWORK":
                // Netzwerk nicht verbunden
                msg = "Sieht aus, als sei deine Netzwerkverbindung gestört.";
                code = "N";
                break;
            default:
                if (this.axios_error.response === undefined)
                    return result();
                switch (this.axios_error.response.status) {
                    case 401:
                        // UNAUTHORIZED
                        msg = "Netter Versuch :)";
                        code = "A";
                        break;
                    case 422:
                        // UNPROCESSABLE ENTITY
                        msg = "Funktion ist kaputt, bitte Admin benachrichtigen!";
                        code = "I";
                        break;
                    default:
                        // HTTP
                        code = `H${this.axios_error.response.status}`;
                        break;
                }
                break;
        }
        return result();
    }
    alert() {
        toast({
            message: this.format(),
            type: "is-danger",
        });
    }
}
