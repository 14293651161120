export class Vector2D {
    x;
    y;
    constructor(x = 0, y = 0) {
        this.x = x;
        this.y = y;
    }
    plus(other) {
        return new Vector2D(this.x + other.x, this.y + other.y);
    }
    minus(other) {
        return new Vector2D(this.x - other.x, this.y - other.y);
    }
    scale(other) {
        return new Vector2D(this.x * other, this.y * other);
    }
    equals(other) {
        return this.x === other.x && this.y === other.y;
    }
}
