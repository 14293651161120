import { APIError } from "./api_error";
export function objForEach(obj, f) {
    for (const k in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, k)) {
            f(k, obj[k]);
        }
    }
}
export function createDebounce() {
    let timeout = undefined;
    return (f, delay_ms) => {
        clearTimeout(timeout);
        timeout = setTimeout(f, delay_ms || 500);
    };
}
export function handle_error(error) {
    if (error instanceof APIError) {
        error.alert();
    }
    else {
        console.error(error);
    }
}
export function get_file(event) {
    const file_input = event.target;
    if (!(file_input instanceof HTMLInputElement))
        return null;
    if (file_input.files === null)
        return null;
    if (file_input.files.length === 0)
        return null;
    const result = file_input.files[0];
    file_input.value = "";
    return result;
}
export function loading_success(o) {
    if (o === "loading")
        return false;
    if (o === "error")
        return false;
    return true;
}
