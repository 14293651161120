import { Vector2D } from "./vector2d";
export class Rectangle {
    corner_1;
    corner_2;
    constructor(corner_1 = new Vector2D(), corner_2 = new Vector2D()) {
        this.corner_1 = corner_1;
        this.corner_2 = corner_2;
    }
    get origin() {
        return new Vector2D(Math.min(this.corner_1.x, this.corner_2.x), Math.min(this.corner_1.y, this.corner_2.y));
    }
    get left() {
        return this.origin.x;
    }
    get top() {
        return this.origin.y;
    }
    get corner() {
        return new Vector2D(Math.max(this.corner_1.x, this.corner_2.x), Math.max(this.corner_1.y, this.corner_2.y));
    }
    get size() {
        return this.corner.minus(this.origin);
    }
    get width() {
        return this.size.x;
    }
    get height() {
        return this.size.y;
    }
    get middle() {
        return this.origin.plus(this.size.scale(0.5));
    }
    get area() {
        return this.width * this.height;
    }
    equals(other) {
        return this.origin.equals(other.origin) && this.corner.equals(other.corner);
    }
    contains(point) {
        return (point.x >= this.origin.x &&
            point.y >= this.origin.y &&
            point.x <= this.corner.x &&
            point.y <= this.corner.y);
    }
    update(corner_1, corner_2) {
        return new Rectangle(corner_1 || this.corner_1, corner_2 || this.corner_2);
    }
    move(vector) {
        return new Rectangle(this.corner_1.plus(vector), this.corner_2.plus(vector));
    }
}
