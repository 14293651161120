import { acceptHMRUpdate, defineStore } from "pinia";
import { API } from "../api";
import { APIError } from "../api_error";
import { handle_error, loading_success } from "../helpers";
import { UserStore } from "./user";
export const PublicStore = defineStore({
    id: "public",
    state: () => ({
        user: null,
        events: "loading",
        selected_event: "loading",
        background: "loading",
        website_config: {
            html_title: "",
            title: "Airsoft Operations",
            subtitle: "Campingplatz Reservierungen",
            info: "",
            footer: "Airsoft Operations Campingplatz Reservierung",
            msg_no_events: "",
            msg_no_picture: "",
        },
    }),
    actions: {
        init() {
            this.reload();
            // try to refresh token every 5 minutes
            setInterval(async () => {
                if (this.user === null)
                    return;
                try {
                    API.token = await API.request("user/refresh");
                }
                catch { }
            }, 300e3);
        },
        select_event(event_id) {
            this.background = "loading";
            API.request(`event/${event_id}/background`)
                .then((img) => (this.background = img))
                .catch(() => (this.background = "error"));
            this.selected_event = "loading";
            API.request(`event/${event_id}`)
                .then((event) => (this.selected_event = event))
                .catch((e) => {
                e.alert();
            });
            if (this.user !== null) {
                UserStore().load(event_id);
            }
        },
        reload_event() {
            if (!loading_success(this.selected_event))
                return;
            this.select_event(this.selected_event.id);
        },
        is_selected(event_id) {
            if (!loading_success(this.selected_event))
                return false;
            return this.selected_event.id === event_id;
        },
        async reload_list() {
            try {
                this.events = await API.request("event/list");
            }
            catch (error) {
                this.events = "error";
                handle_error(error);
            }
        },
        async reload() {
            // update user
            const [user, website_config] = await Promise.allSettled([
                API.request("user/current"),
                API.request("general/website_config"),
            ]);
            if (user.status == "fulfilled") {
                this.user = user.value;
            }
            else {
                this.user = null;
            }
            if (website_config.status == "fulfilled") {
                this.website_config = website_config.value;
                document.title = this.website_config.html_title;
            }
            await this.reload_list();
            if (!loading_success(this.events))
                return;
            if (this.events.length === 0)
                return;
            this.select_event(this.events[0].id);
        },
        async login(creds) {
            try {
                const token = await API.request({
                    method: "POST",
                    endpoint: "user/login",
                    data: new URLSearchParams({
                        username: creds.mail,
                        password: creds.password,
                    }),
                });
                API.token = token.access_token;
            }
            catch { }
            await this.reload();
        },
        async logout() {
            API.token = null;
            this.user = null;
            await this.reload();
        },
    },
});
if (import.meta.webpackHot) {
    import.meta.webpackHot.accept(acceptHMRUpdate(PublicStore, import.meta.webpackHot));
}
