import axios, {} from "axios";
import { APIError } from "./api_error";
export class API {
    static get api_baseurl() {
        // in production mode, return "proto://hostname/api"
        if (process.env.NODE_ENV === "production") {
            return `${window.location.protocol}//${window.location.host}/api`;
        }
        else if (process.env.NODE_ENV !== "development") {
            // not in prouction or development mode
            console.warn("Unexpected NODE_ENV value");
        }
        // in development mode, return "proto://hostname:8000/api"
        return `${window.location.protocol}//${window.location.hostname}:8000/api`;
    }
    static axios = axios.create({
        timeout: 10e3,
        baseURL: this.api_baseurl,
    });
    static storage_key = "camping24/token";
    static set token(value) {
        if (value === null) {
            localStorage.removeItem(this.storage_key);
            return;
        }
        localStorage.setItem(this.storage_key, value);
    }
    static get token() {
        return localStorage.getItem(this.storage_key);
    }
    static get_axios_config({ endpoint, method = "GET", data, headers = {}, config = {}, }) {
        const result = {
            url: endpoint,
            method: method,
            data: data,
            headers: headers,
            ...config,
        };
        if (this.token !== null) {
            result.headers.Authorization = `Bearer ${this.token}`;
        }
        return result;
    }
    static async request(p) {
        if (typeof p === "string")
            p = { endpoint: p };
        try {
            const response = await this.axios.request(this.get_axios_config(p));
            return response.data;
        }
        catch (reason) {
            console.error(`Failed to query ${p.endpoint}: ${reason}`);
            throw new APIError(reason, p.endpoint);
        }
    }
}
