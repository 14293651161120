import { PublicStore } from "@/lib/store/public";
import { FontAwesomePlugin } from "@/plugins/fontawesome";
import * as bulmaToast from "bulma-toast";
import { createPinia } from "pinia";
import { createApp } from "vue";
import App from "./App.vue";
import "@/main.scss";
const app = createApp(App);
app.use(FontAwesomePlugin);
app.use(createPinia());
PublicStore().init();
app.mount("#app");
bulmaToast.setDefaults({
    duration: 10e3,
    pauseOnHover: true,
    dismissible: true,
    closeOnClick: false,
    type: "is-white",
    position: "top-center",
    animate: { in: "backInDown", out: "backOutUp" },
});
