import { acceptHMRUpdate, defineStore } from "pinia";
import { API } from "../api";
import { handle_error } from "../helpers";
import { Pitch } from "../rects/pitch";
import { PublicStore } from "./public";
export const UserStore = defineStore({
    id: "user",
    state: () => ({
        event: null,
        pitches_edit: [],
        ticket_counts: {},
    }),
    actions: {
        async load(event_id) {
            // load event
            try {
                this.event = await API.request(`event/${event_id}/full`);
                this.ticket_counts = {};
                for (const guest of this.event.guests) {
                    this.ticket_counts[guest.id] = {
                        used: 0,
                        total: guest.tickets,
                    };
                }
                for (const pitch of this.event.pitches) {
                    if (pitch.booking === null)
                        continue;
                    this.ticket_counts[pitch.booking.guest.id].used += 1;
                }
                this.pitches_edit = this.event.pitches.map(Pitch.load);
            }
            catch (error) {
                handle_error(error);
            }
        },
        async reload() {
            if (this.event === null)
                return;
            await PublicStore().reload_list();
            await this.load(this.event.id);
        },
        async save(event) {
            if (this.event === null)
                return;
            try {
                await API.request({
                    method: "PUT",
                    endpoint: `event/${this.event.id}`,
                    data: event,
                });
            }
            catch (error) {
                handle_error(error);
            }
        },
    },
});
if (import.meta.webpackHot) {
    import.meta.webpackHot.accept(acceptHMRUpdate(UserStore, import.meta.webpackHot));
}
