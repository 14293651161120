/* import the fontawesome core */
import { library } from "@fortawesome/fontawesome-svg-core";
/* import specific icons */
// import { fab } from "@fortawesome/free-brands-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
/* add icons to the library */
// library.add(fas, fab);
library.add(fas);
/* import font awesome icon component */
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
export const FontAwesomePlugin = {
    install(app) {
        app.component("FontAwesomeIcon", FontAwesomeIcon);
    },
};
